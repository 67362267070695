/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Toast, Card, DropZone, Tabs, Layout, Page, Button, InlineError, Icon, Tooltip, Stack, TextField, ContextualSaveBar, Select, TextStyle, FormLayout, Label, Banner, Checkbox, DataTable } from '@shopify/polaris';
import axios from 'axios';
import moment from 'moment-timezone';
import Datetime from 'react-datetime';
import { HideMinor, SecureMajor, ViewMinor, InfoMinor, DeleteMajor, EmbedMinor } from '@shopify/polaris-icons';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import Banners from '../Shared/Banners';
import TinymceEditor from '../Tinymce/TinymceEditor';
import SwitchToggle from '../Shared/SwitchToggle';
import { minutes } from '../Shared/Constants/Hours';
import { getTabId, getTabIndex, onTabSelect } from '../FilterEngine/Tabs/tabs';
import { stateConverter } from '../FilterEngine/filterParams';
import CustomFields from './CustomFields';
import StaffAccounts from './StaffAccounts';
import ProductBookings from '../ProductBookings/ProductBookings';
import AvailabilityTab from './AvailabilityTab';
import Tags from '../Tags/Tags';
import TagModifier from '../Tags/TagModifier';
import usePrevious from '../../hooks/usePrevious';
import EmbeddedProductsModal from './EmbeddedProductsModal';
import EmailTemplate from '../Settings/EmailTemplates/EmailTemplate';
import GenericFormBuilder from '../Shared/GenericFormBuilder';
import { ElementStore } from '../Shared/FormBuilder';
import ModalService from '../Shared/Services/ModalService';
import PreviewFormBuilderModal from '../Shared/FormBuilder/PreviewFormBuilderModal';

const previewModalRef = React.createRef();

const Product = (props) => {
  const [init, setInit] = useState(false);
  const [initTags, setInitTags] = useState(false);
  const [showSaveBar, setShowSaveBar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [initialTags, setInitialTags] = useState([]);
  const [navigateTo, setNavigateTo] = useState('');
  const [owner, setOwner] = useState({});
  const [banner, setBanner] = useState([]);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [uniquenessError, setUniquenessError] = useState(false);
  const [bookingFormData, setBookingFormData] = useState([]);
  const [product, setProduct] = useState({
    participation_limit: '1',
    duration: '60',
    product_settings: {}
  });
  const [productImage, setProductImage] = useState(null);
  const [customFields, setCustomFields] = useState([]);
  const [customFieldError, setCustomFieldError] = useState(false);

  const { t } = useTranslation();
  const location = useLocation();
  const history = useNavigate();
  const matchParams = useParams();

  const [toastMessage, setToastMessage] = useState('');
  const [toastActive, setToastActive] = useState(false);
  const toggleToastActive = useCallback((message) => {
    setToastActive(!toastActive);
    setToastMessage(message);
  }, [toastActive]);

  const toastMarkup = toastActive ? (
    <Toast content={toastMessage} onDismiss={toggleToastActive} duration={2000} />
  ) : null;

  const [tableFilters, setTableFilters] = useState(stateConverter(location));

  const handleCustomFieldChange = (idx) => (field) => (value) => {
    const temp = [...customFields];
    temp[idx][field] = value;

    setCustomFields(temp);
    setShowSaveBar(true);
  };

  const deleteCustomField = (id) => {
    const temp = [...customFields];
    temp[id]._destroy = '1';
    setCustomFields([...temp]);
    setShowSaveBar(true);
  };

  const addCustomField = () => {
    setCustomFields([...customFields, {
      order_number: customFields.length
    }]);
  };

  let tabs = [
    {
      id: 'Product',
      content: t('products.plural'),
      accessibilityLabel: t('products.plural'),
      panelID: 'Product'
    },
    {
      id: 'Availability',
      content: t('product.availability'),
      accessibilityLabel: 'Availability',
      panelID: 'Availability'
    },
    {
      id: 'Staff',
      content: t('staff.staff'),
      accessibilityLabel: 'Staff',
      panelID: 'Staff'
    },
    {
      id: 'BookingFields',
      content: `${t('product.custom_booking_fields')} (deprecated)`,
      accessibilityLabel: t('product.custom_booking_fields'),
      panelID: 'BookingFields'
    },
    {
      id: 'NewBookingFields',
      content: 'New Custom Booking Fields',
      accessibilityLabel: 'NewCustomBookingFields',
      panelID: 'NewBookingFields'
    },
    {
      id: 'AdvancedSettings',
      content: t('product.advanced_settings'),
      accessibilityLabel: t('product.advanced_settings'),
      panelID: 'AdvancedSettings'
    },
    {
      id: 'EmailTemplate',
      content: t('email_template.title'),
      accessibilityLabel: t('email_template.title'),
      panelID: 'EmailTemplate'
    },
    {
      id: 'Bookings',
      content: t('client.bookings'),
      accessibilityLabel: t('client.bookings'),
      panelID: 'Bookings'
    }
  ];

  if (!props.bookingPageAccess) {
    tabs = [
      {
        id: 'Availability',
        content: t('product.availability'),
        accessibilityLabel: 'Availability',
        panelID: 'Availability'
      }
    ];
  }

  if (product.one_time_event) {
    tabs.splice(tabs.findIndex((i) => i.id === 'Availability'), 1);
  }

  if (props.isStaff && props.bookingPageAccess) {
    tabs.splice(tabs.findIndex((i) => i.id === 'EmailTemplate'), 1);
  }

  const fetchProductData = useCallback(() => {
    if (matchParams.token !== 'new_event') {
      setLoading(true);
      axios.get(`/v1/products/${matchParams.token}`)
        .then((res) => {
          const startDate = new Date();
          const endDate = new Date();
          startDate.setHours(14);
          startDate.setMinutes(0);
          endDate.setHours(15);
          endDate.setMinutes(0);
          if (res.data.product_image) setProductImage(new File([''], res.data.product_image));
          if (res.data.schedule) res.data.schedule = JSON.parse(res.data.schedule);
          setProduct(res.data);
          setOwner(res.data.owner);
          setSelectedTags(res.data.selectedTags);
          setInitialTags(res.data.selectedTags);
          setInitTags(true);

          const data = JSON.parse(res.data.form_data);
          setBookingFormData(data);
          ElementStore.state.data = data;
          if (!res.data.p_start_time) setProduct({ ...res.data, p_start_time: startDate, p_end_time: endDate });
          res.data.custom_fields.forEach((r, i) => {
            if (!r.order_number) {
              // eslint-disable-next-line no-param-reassign
              r.order_number = i;
            }
          });

          setCustomFields(res.data.custom_fields);
        })
        .catch(() => {})
        .then(() => {
          setLoading(false);
        });
    }
  // eslint-disable-next-line
  }, [matchParams.token]);

  const dismissBanner = () => {
    setBanner([]);
  };

  useEffect(() => {
    // ElementStore.state.data = [];

    fetchProductData();
  }, [fetchProductData]);

  useEffect(() => {
    if (getTabId(tabs, tableFilters.tab) === 'NewBookingFields') {
      ElementStore.state.data = bookingFormData;
    }
    // eslint-disable-next-line
  }, [tableFilters.tab]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const staff = query.get('staff');

    if (staff) setNavigateTo(`/staffs/${staff}?tab=Products`);
    // eslint-disable-next-line
  }, []);

  const handleTinymceChange = (value) => {
    if (init) setShowSaveBar(true);
    setProduct({ ...product, description: value });
    setInit(true);
  };

  const handleChange = (field) => (value) => {
    setShowSaveBar(true);
    setProduct({ ...product, [field]: value });
  };

  const handleProductSettingChange = (field) => (value) => {
    const temp = { ...product.product_settings };
    temp[field] = value;
    setProduct({ ...product, product_settings: temp });
    setShowSaveBar(true);
  };

  const resetProductImage = () => {
    setShowSaveBar(true);
    setProductImage(null);
  };

  const uploadedProductImageFile = productImage && (
    <Stack alignment="center">
      <img src={product.product_image} alt="product" width="50%" />
    </Stack>
  );

  const handleProductImageDropZoneDrop = useCallback((_dropFiles, acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      setBanner([{ title: t('shared.upload_avatar_validation'), status: 'critical' }]);
      setTimeout(() => {
        setBanner([]);
      }, 3000);
    } else {
      setProduct({ ...product, product_image: window.URL.createObjectURL(acceptedFiles[0]) });
      setProductImage(acceptedFiles[0]);
      setShowSaveBar(true);
    }
  }, [t, product]);

  const handleTimeChange = (key) => (value) => {
    setShowSaveBar(true);
    setProduct({ ...product, [key]: new Date(value) });
  };

  const handleDragnDropChange = (items) => {
    setCustomFields(items);
    setShowSaveBar(true);
  };

  const handleProductEdit = () => {
    setLoading(true);

    if (customFields.some((customField) => !customField.label && customField._destroy !== '1')) {
      setCustomFieldError(true);
      setLoading(false);
      return;
    }

    const formData = new FormData();

    formData.append('product[title]', product.title || '');
    formData.append('product[description]', product.description || '');
    formData.append('product[big_description]', product.big_description || '');
    formData.append('product[duration]', product.duration || 60);
    formData.append('product[slot_generation]', product.slot_generation || 30);
    formData.append('product[participation_limit]', product.participation_limit || 1);
    formData.append('product[online]', product.online || false);
    formData.append('product[one_time_event]', product.one_time_event || false);
    formData.append('product[p_start_time]', product.p_start_time || null);
    formData.append('product[p_end_time]', product.p_end_time || null);
    formData.append('product[requires_payment]', product.requires_payment || false);
    formData.append('product[requires_login]', product.requires_login || false);
    formData.append('product[requires_to_be_client]', product.requires_to_be_client || false);
    formData.append('product[custom_schedule]', product.custom_schedule || false);
    if (props.hasInvoiceAccess) formData.append('product[price]', product.price || 0);
    formData.append('product[product_settings_attributes][id]', product.product_settings?.id || '');
    formData.append('product[product_settings_attributes][start_date]', product.product_settings?.start_date || '');
    formData.append('product[product_settings_attributes][end_date]', product.product_settings?.end_date || '');
    formData.append('product[product_settings_attributes][buffer]', product.product_settings?.buffer || '');
    formData.append('product[product_settings_attributes][cutoff_days]', product.product_settings?.cutoff_days || '');
    formData.append('product[product_settings_attributes][cutoff_hours', product.product_settings?.cutoff_hours || '');
    formData.append('product[product_settings_attributes][cutoff_minutes]', product.product_settings?.cutoff_minutes || '');
    formData.append('product[product_settings_attributes][book_days_in_the_future]', product.product_settings?.book_days_in_the_future || '');
    formData.append('product[product_settings_attributes][enable_gift_card]', product.product_settings?.enable_gift_card || '');
    formData.append('product[product_settings_attributes][enable_event_package]', product.product_settings?.enable_event_package || '');

    if (Array.isArray(product.schedule)) {
      formData.append('product[schedule]', JSON.stringify(product.schedule) || '');
    }

    if (productImage?.size > 0) {
      formData.append('product[product_image]', productImage);
    }

    if (!productImage) {
      formData.append('remove_product_image', true);
    }

    formData.append('product[form_data]', JSON.stringify(bookingFormData) || JSON.stringify(ElementStore.state.data));

    if (customFields.length) {
      customFields.forEach((customField, i) => {
        if (customField.id) formData.append(`product[custom_fields_attributes][${i}][id]`, customField.id);
        formData.append(`product[custom_fields_attributes][${i}][label]`, customField.label);
        formData.append(`product[custom_fields_attributes][${i}][value]`, customField.value);
        formData.append(`product[custom_fields_attributes][${i}][category]`, customField.category);
        formData.append(`product[custom_fields_attributes][${i}][required]`, customField.required);
        formData.append(`product[custom_fields_attributes][${i}][order_number]`, customField.order_number);

        if (customField._destroy) {
          formData.append(`product[custom_fields_attributes][${i}][_destroy]`, customField._destroy);
        }
      });
    }

    setUniquenessError('');

    if (matchParams.token === 'new_event') {
      axios.post('/v1/products', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          toggleToastActive(t('shared.saved'));
          setTimeout(() => {
            history(`/products/${response.data.token}?tab=Availability`);
          });
        })
        .catch((error) => {
          if (error.response.data?.errors?.includes('Title has already been taken')) {
            setUniquenessError(t('product.title_is_taken'));
          }
          toggleToastActive(t('shared.unable_to_save'));
        })
        .then(() => {
          setShowSaveBar(false);
          setLoading(false);
        });
    } else {
      axios.patch(`/v1/products/${product.token}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then((response) => {
          setProduct(response.data);
          setCustomFields(response.data.custom_fields);
          toggleToastActive(t('shared.saved'));
        })
        .catch((error) => {
          if (error.response.data?.exception?.includes('UniqueViolation')) {
            setUniquenessError(t('product.title_is_taken'));
          }
          toggleToastActive(t('shared.unable_to_save'));
        })
        .then(() => {
          setShowSaveBar(false);
          setLoading(false);
        });
    }
  };

  const handleActiveToggle = (id, action) => {
    axios.post(`/v1/products/${id}/${action}`)
      .then(() => {
        setProduct({ ...product, status: product.active ? 'inactive' : 'active', active: !product.active });
        toggleToastActive(t('shared.saved'));
      })
      .catch(() => {
        toggleToastActive(t('shared.unable_to_save'));
      });
  };

  const handleDelete = (token) => {
    if (!window.confirm(`${t('shared.are_you_sure')} ${t('products.plural')}`)) return;

    axios.delete(`/v1/products/${token}`)
      .then(() => {
        history('/products');
        toggleToastActive(t('shared.success'));
      })
      .catch(() => {
        toggleToastActive(t('shared.something_went_wrong'));
      });
  };

  let embeddedProductsModal = null;
  if (showProductsModal) {
    embeddedProductsModal = (
      <EmbeddedProductsModal
        token={product.token}
        title={t('embed.product')}
        cleanup={() => setShowProductsModal(false)}
      />
    );
  }

  const pageActions = () => {
    if (!product || loading || matchParams.token === 'new_event') {
      return {};
    }

    const toggleContent = product.active ? 'Inactivate' : 'Activate';
    const content = product.active ? t('client.inactivate') : t('client.activate');

    const actions = [{
      icon: EmbedMinor,
      content: t('embed.product'),
      onAction: () => setShowProductsModal(true)
    }, {
      icon: product.active ? HideMinor : ViewMinor,
      content,
      onAction: () => handleActiveToggle(product.token, toggleContent.toLowerCase())
    }];

    actions.push({
      icon: DeleteMajor,
      content: 'Delete',
      onAction: () => handleDelete(product.token)
    });

    return actions;
  };

  const renderSingleDateInput = (_, openCalendar) => (
    <Button plain type="button" onClick={openCalendar}>{t('shared.change')}</Button>
  );

  const saveBarText = () => {
    if (matchParams.token !== 'new_event') {
      return t('shared.unsaved_changes');
    }

    return t('product.creation');
  };

  const updateTags = useCallback(() => {
    setInitTags(false);

    const params = {
      product: {
        tags_attributes: TagModifier(selectedTags, initialTags)
      }
    };

    axios.patch(`/v1/products/${matchParams.token}`, params)
      .then((response) => {
        setSelectedTags(response.data.selectedTags);
        setInitialTags(response.data.selectedTags);
        setInitTags(true);
      })
      .catch(() => {
      });
  }, [matchParams.token, selectedTags, initialTags]);

  const previousTags = usePrevious(selectedTags);

  useEffect(() => {
    if (initTags && JSON.stringify(previousTags) !== JSON.stringify(selectedTags)) {
      updateTags();
    }
  // eslint-disable-next-line
  }, [selectedTags]);

  const openModal = async () => {
    await previewModalRef.current.open({
      cancelButton: 'Cancel',
      submitButton: 'Ok',
      data: bookingFormData,
      submit: () => {}
    });
  };

  const eventHeadings = [
    'Events'
  ];

  const modifier = useCallback(
    (tableData) => tableData.map((row) => {
      const modifiedRow = [];

      modifiedRow.push(<Button plain url={`/events/${row.id}`}>{moment(row.start).format('llll')}</Button>);

      return modifiedRow;
    }),
    // eslint-disable-next-line
    []
  );

  return (
    <Page
      title={t('products.plural')}
      fullWidth
      separator
      secondaryActions={pageActions()}
      breadcrumbs={[{ content: t('shared.back'), onAction: () => (navigateTo ? history(navigateTo) : history('/products')) }]}
    >
      {embeddedProductsModal}
      {toastMarkup}
      {showSaveBar && (
        <ContextualSaveBar
          message={saveBarText()}
          saveAction={{
            onAction: () => handleProductEdit(),
            loading,
            disabled: false
          }}
          discardAction={{
            onAction: () => window.location.reload()
          }}
        />
      )}
      <Banners banners={banner} onDismissBanner={dismissBanner} />
      <Tabs
        tabs={tabs}
        selected={getTabIndex(tabs, tableFilters.tab)}
        onSelect={(tabIndex) => onTabSelect({ history, location }, tabs, tabIndex, setTableFilters)}
      >
        <br />
        {owner.email !== props.email && matchParams.token !== 'new_event' && !loading ? (
          <div className="mb-8">
            <Banner status="info">
              {`Product Owner: ${owner.name} (${owner.email})`}
            </Banner>
          </div>
        ) : null}
        {getTabId(tabs, tableFilters.tab) === 'Product' && (
          <Layout>
            <Layout.Section oneHalf>
              <Card sectioned>
                <Card.Section title={t('product.image')} actions={[{ content: t('shared.change'), onAction: () => resetProductImage() }]}>
                  {!productImage ? (
                    <DropZone type="image" accept="image/*" allowMultiple={false} onDrop={handleProductImageDropZoneDrop}>
                      <DropZone.FileUpload />
                    </DropZone>
                  ) : uploadedProductImageFile}
                </Card.Section>
                <Card.Section>
                  <TextField
                    label={t('shared.title')}
                    requiredIndicator
                    value={product.title}
                    onChange={handleChange('title')}
                    error={uniquenessError}
                  />
                </Card.Section>
                <Card.Section title={t('shared.description')}>
                  <TinymceEditor
                    value={product.description}
                    handleChange={handleTinymceChange}
                  />
                </Card.Section>
              </Card>
            </Layout.Section>
            <Layout.Section oneHalf>
              {matchParams.token !== 'new_event' && (
                <Card
                  sectioned
                  title="Tags"
                >
                  <Tags
                    selectedTags={selectedTags}
                    setSelectedTags={setSelectedTags}
                    tagUrl={`/v1/products/${matchParams.token}/tags`}
                  />
                </Card>
              )}
              <Card
                sectioned
              >
                <Card.Section title={t('product.security.title')}>
                  <Stack alignment="center">
                    <Stack.Item fill>
                      <Stack wrap={false} spacing="tight">
                        <Label>{t('product.security.label')}</Label>
                        <Icon color="critical" source={SecureMajor} />
                      </Stack>
                      <TextStyle variation="subdued">{t('product.security.anyone_can_book')}</TextStyle>
                    </Stack.Item>
                    <Stack.Item>
                      <SwitchToggle
                        handleChange={handleChange('requires_login')}
                        checked={product.requires_login}
                      />
                    </Stack.Item>
                  </Stack>
                </Card.Section>
                {props.hasInvoiceAccess && (
                  <Card.Section title={t('product.pricing')}>
                    <Stack alignment="center">
                      <Stack.Item fill>
                        <TextField
                          label="Price"
                          type="currency"
                          value={product.price}
                          onChange={handleChange('price')}
                          helpText={t('product.pricing_toggle')}
                        />
                      </Stack.Item>
                      <Stack.Item>
                        <SwitchToggle
                          handleChange={handleChange('requires_payment')}
                          checked={product.requires_payment}
                        />
                      </Stack.Item>
                    </Stack>
                  </Card.Section>
                )}
                {!product.recurring && (
                  <Card.Section title="Online Event">
                    <Stack alignment="center">
                      <Stack.Item fill wrap={false}>
                        <Stack>
                          <TextStyle variation="subdued">
                            {t('product.online_invitation')}
                          </TextStyle>
                          {!props.canDoOnlineEvent ? (
                            <Tooltip
                              content={(
                                <FormLayout>
                                  {t('settings.google_calendar_info')}
                                </FormLayout>
                              )}
                            >
                              <Icon color="highlight" source={InfoMinor} />
                            </Tooltip>
                          ) : ''}
                        </Stack>
                      </Stack.Item>
                      <Stack.Item>
                        <SwitchToggle
                          disabled={!props.canDoOnlineEvent}
                          handleChange={handleChange('online')}
                          checked={product.online}
                        />
                      </Stack.Item>
                    </Stack>
                  </Card.Section>
                )}
                {!product.recurring && (
                  <Card.Section title={t('shared.time')}>
                    {!product.token && (
                      <Stack alignment="center">
                        <Stack.Item fill>
                          <TextStyle>{t('event.single')}</TextStyle>
                          <br />
                          <TextStyle variation="subdued">{t('product.single_event_desc')}</TextStyle>
                        </Stack.Item>
                        <Stack.Item>
                          <SwitchToggle
                            handleChange={handleChange('one_time_event')}
                            checked={product.one_time_event}
                          />
                        </Stack.Item>
                      </Stack>
                    )}
                    <br />
                    {!product.one_time_event ? (
                      <Stack alignment="center">
                        <Stack.Item fill>
                          <TextField
                            label={t('product.duration')}
                            type="number"
                            value={product.duration}
                            onChange={handleChange('duration')}
                            helpText={t('product.duration_desc')}
                          />
                        </Stack.Item>
                        <Stack.Item fill>
                          <Select
                            label={t('product.time_slots')}
                            value={product.slot_generation || ''}
                            helpText={t('product.time_slots_desc')}
                            onChange={handleChange('slot_generation')}
                            options={[
                              { value: '15', label: t('product.time.every_15_min') },
                              { value: '30', label: t('product.time.every_30_min') },
                              { value: '45', label: t('product.time.every_45_min') },
                              { value: '60', label: t('product.time.every_60_min') },
                              { value: '90', label: t('product.time.every_90_min') },
                              { value: '120', label: t('product.time.every_120_min') }
                            ]}
                          />
                        </Stack.Item>
                      </Stack>
                    ) : (
                      <>
                        <TextStyle>{t('shared.start')}</TextStyle>
                        <Stack>
                          {product.p_start_time && <TextStyle>{moment(product.p_start_time).format('LLLL')}</TextStyle>}
                          <Datetime
                            renderInput={renderSingleDateInput}
                            timeConstraints={{ minutes: { step: 15 } }}
                            value={new Date(product.p_start_time)}
                            onChange={handleTimeChange('p_start_time')}
                          />
                        </Stack>
                        <br />
                        <TextStyle>{t('shared.end')}</TextStyle>
                        <Stack>
                          {product.p_end_time && <TextStyle>{moment(product.p_end_time).format('LLLL')}</TextStyle>}
                          <Datetime
                            renderInput={renderSingleDateInput}
                            timeConstraints={{ minutes: { step: 15 } }}
                            value={new Date(product.p_end_time)}
                            onChange={handleTimeChange('p_end_time')}
                          />
                        </Stack>
                        <br />
                        {new Date(product.p_end_time) <= new Date(product.p_start_time) ? <InlineError message="End of event must be after start" fieldID="startDateId" /> : null}
                      </>
                    )}
                  </Card.Section>
                )}
                {!product.recurring && (
                  <Card.Section title={t('shared.participation_limit')}>
                    <TextField
                      type="number"
                      value={product.participation_limit}
                      onChange={handleChange('participation_limit')}
                      helpText={t('shared.participation_limit_desc')}
                    />
                  </Card.Section>
                )}
              </Card>
              {product.recurring && (
                <Card>
                  {/* {(product.events || []).map((event) => (
                    <Card.Section title="">
                      {event.id}
                    </Card.Section>
                  ))} */}
                  <DataTable
                    columnContentTypes={[
                      'text',
                      'text',
                      'text',
                      'text',
                      'text',
                      'text'
                    ]}
                    verticalAlign="middle"
                    headings={eventHeadings}
                    rows={modifier(product.events)}
                  />
                </Card>
              )}
            </Layout.Section>
          </Layout>
        )}
        {getTabId(tabs, tableFilters.tab) === 'Availability' && !product.one_time_event && (
          matchParams.token !== 'new_event' ? <AvailabilityTab /> : (
            <Banner status="info">
              <p>{t('product.needs_to_be_created_before_availability')}</p>
            </Banner>
          )
        )}
        {getTabId(tabs, tableFilters.tab) === 'Staff' && (
          matchParams.token !== 'new_event' ? <StaffAccounts owner={owner.email} isStaff={props.isStaff} /> : (
            <Banner status="info">
              <p>{t('product.created_before_staff_can_be_added')}</p>
            </Banner>
          )
        )}
        {getTabId(tabs, tableFilters.tab) === 'BookingFields' && (
          <CustomFields
            customFields={customFields}
            handleCustomFieldChange={handleCustomFieldChange}
            deleteCustomField={deleteCustomField}
            addCustomField={addCustomField}
            error={customFieldError}
            setError={setCustomFieldError}
            setCustomFields={handleDragnDropChange}
            title={t('custom_fields.plural')}
          />
        )}
        {getTabId(tabs, tableFilters.tab) === 'NewBookingFields' && (
          <>
            <Button onClick={() => openModal()}>Preview</Button>
            <GenericFormBuilder
              data={bookingFormData}
              setData={(d) => {
                if (!loading) {
                  setBookingFormData(d);
                  setShowSaveBar(true);
                }
              }}
            />
          </>
        )}
        {getTabId(tabs, tableFilters.tab) === 'EmailTemplate' && (
          <EmailTemplate path="booking_mailer/client_confirmation" hideNav subtype={product.token} isIndividualProduct />
        )}
        {getTabId(tabs, tableFilters.tab) === 'AdvancedSettings' && (
          <div style={{ maxWidth: '800px', margin: 'auto' }}>
            <Layout>
              {!product.one_time_event && (
                <Layout.AnnotatedSection
                  title={t('product.start_time')}
                  description={t('product.start_date_desc')}
                >
                  <Card sectioned>
                    <TextField
                      type="date"
                      value={product.product_settings.start_date}
                      onChange={handleProductSettingChange('start_date')}
                    />
                  </Card>
                </Layout.AnnotatedSection>
              )}
              {!product.one_time_event && (
                <Layout.AnnotatedSection
                  title={t('product.end_date')}
                  description={t('product.end_date_desc')}
                >
                  <Card sectioned>
                    <TextField
                      type="date"
                      value={product.product_settings.end_date}
                      onChange={handleProductSettingChange('end_date')}
                    />
                  </Card>
                </Layout.AnnotatedSection>
              )}
              {!product.one_time_event && (
                <Layout.AnnotatedSection
                  title={t('product.can_book_into_the_future')}
                >
                  <Card sectioned>
                    <TextField
                      type="number"
                      value={product.product_settings.book_days_in_the_future}
                      onChange={handleProductSettingChange('book_days_in_the_future')}
                    />
                  </Card>
                </Layout.AnnotatedSection>
              )}
              {!product.one_time_event && (
                <Layout.AnnotatedSection
                  title={t('product.buffer')}
                  description={t('product.time_between_events')}
                >
                  <Card sectioned>
                    <Select
                      value={product.product_settings.buffer}
                      onChange={handleProductSettingChange('buffer')}
                      options={minutes}
                    />
                  </Card>
                </Layout.AnnotatedSection>
              )}
              <Layout.AnnotatedSection
                title={t('product.registration_deadline')}
                description={t('product.registration_deadline_desc')}
              >
                <Card sectioned>
                  <TextField
                    type="number"
                    label={t('shared.days')}
                    value={product.product_settings.cutoff_days}
                    onChange={handleProductSettingChange('cutoff_days')}
                  />
                  <TextField
                    type="number"
                    max={23}
                    label={t('shared.hours')}
                    value={product.product_settings.cutoff_hours}
                    onChange={handleProductSettingChange('cutoff_hours')}
                  />
                  <TextField
                    type="number"
                    label={t('shared.minutes')}
                    max={59}
                    onChange={handleProductSettingChange('cutoff_minutes')}
                    value={product.product_settings.cutoff_minutes}
                  />
                </Card>
              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection
                title="Enable Gift Cards"
                description="Allows the use of gift cards in the checkout"
              >
                <Card sectioned>
                  <Checkbox
                    checked={product.product_settings.enable_gift_card}
                    onChange={handleProductSettingChange('enable_gift_card')}
                  />
                </Card>
              </Layout.AnnotatedSection>
              <Layout.AnnotatedSection
                title="Enable Event Packages"
                description="Allows the use of event packages in the checkout"
              >
                <Card sectioned>
                  <Checkbox
                    checked={product.product_settings.enable_event_package}
                    onChange={handleProductSettingChange('enable_event_package')}
                  />
                </Card>
              </Layout.AnnotatedSection>
            </Layout>
          </div>
        )}
        {getTabId(tabs, tableFilters.tab) === 'Bookings' && (
          product.id && <ProductBookings product_id={product.id} />
        )}
      </Tabs>
      <ModalService ref={previewModalRef}>
        <PreviewFormBuilderModal />
      </ModalService>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  canDoOnlineEvent: state.auth.canDoOnlineEvent,
  hasInvoiceAccess: state.auth.hasInvoiceAccess,
  isStaff: state.auth.role === 'staff',
  email: state.auth.email,
  bookingPageAccess: state.auth.bookingPageAccess
});

export default connect(mapStateToProps)(Product);
