/* eslint-disable arrow-body-style */
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { LockClosedIcon } from '@heroicons/react/20/solid';
import defaultImage from '../../assets/images/default_product.jpg';
import Badges from '../Shared/Badges';

// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ');
// }

const ViewableProducts = (props) => {
  const history = useNavigate();
  const matchParams = useParams();

  const { t } = useTranslation();

  const onSelect = (product) => {
    props.setSelected(product);

    if (props.hideSearch) {
      props.hideSearch(product);
    }

    if (props.type === 'profile') {
      history(`/booking/${matchParams.name}?tab=booking&profile_token=${product.token}`);
    } else {
      history(`/booking/${matchParams.name}?tab=booking&token=${product.token}`);
    }
  };

  return (
    <div className="bg-white2">
      <div className="mx-auto overflow-hidden">
        <h2 className="sr-only">Products</h2>

        <div className="flex justify-center flex-wrap">
          {props.products.map((product) => (
            <div role="button" onKeyDown={() => {}} tabIndex={0} key={product.token} className="relative p-2 mb-4 border-gray-200 w-1/2 sm:w-1/2 md:w-1/3 lg:w-1/4" onClick={() => onSelect(product)}>
              {product.requires_login && (
                <div className="absolute bottom-2 right-2 z-20 h-5 w-5">
                  <LockClosedIcon className="fill-blue-500" />
                </div>
              )}
              <div className="overflow-hidden bg-gray-200 aspect-w-1 aspect-h-1 group-hover:opacity-75">
                <img
                  src={product.product_image || defaultImage}
                  alt={product.title}
                  className="w-full object-center object-cover"
                />
              </div>
              <div className="pt-2 mb-1 text-center">
                <p className="text-lg font-medium text-gray-900">
                  <a href={product.href}>
                    <span aria-hidden="true" className="absolute inset-0" />
                    {product.title}
                  </a>
                </p>
                {product.one_time_event && !product.recurring && (
                  <>
                    <div className="mt-3 flex flex-col items-center">
                      <p>
                        {`${t('time.from')} ${moment(product.p_start_time).format('LL')}`}
                      </p>
                    </div>
                    <div className="flex flex-col items-center">
                      <p>
                        {`${t('time.to')} ${moment(product.p_end_time).format('LL')}`}
                      </p>
                    </div>
                  </>
                )}
                {product.recurring && (
                  <>
                    <div className="mt-3 flex flex-col items-center">
                      <p>
                        {`Number of events: ${product.event_count}`}
                      </p>
                    </div>
                    <div className="flex flex-col items-center">
                      <p>
                        {`Start: ${moment(product.event_start).format('LL')}`}
                      </p>
                    </div>
                    <div className="flex flex-col items-center">
                      <p>
                        {`End: ${moment(product.event_end).format('LL')}`}
                      </p>
                    </div>
                  </>
                )}
                {product.price && <p className="mt-4 text-base font-medium text-gray-900">{product.price}</p>}
              </div>
              {/* filter by props.lang */}
              {product.tags && props.type !== 'profile' && (
                <div className="px-1 max-h-16 overflow-hidden">
                  <Badges textOnly status="info" color="bg-white" data={product.tags} class="text-justify" />
                </div>
              )}
              {product.tags && props.type === 'profile' && (
                <div className="px-1 max-h-16 overflow-hidden">
                  <Badges textOnly data={product.tags.filter((tag) => tag.language === props.lang)} class="text-justify" />
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ViewableProducts;
